import { Box, Button, Modal, Typography } from '@mui/material';
import { navigate } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { useGetArticleList } from '../../../hooks';
import { CartItem } from '../../../store/interfaces';
import * as styles from './check-if-article-is-byuable.module.scss';

interface CheckIfArticlesAreBuyableProps {
  cartItems: CartItem[];
}
export const CheckIfArticlesAreBuyable = ({
  cartItems,
}: CheckIfArticlesAreBuyableProps) => {
  const shopArticles = useGetArticleList();
  const [missingArticles] = useState<CartItem[]>([]);

  useEffect(() => {
    for (const cartArticle of cartItems) {
      const matchedShopArticle = shopArticles.find(
        (sa) => sa.articleNumber === cartArticle.articleNumber,
      );
      if (!matchedShopArticle) {
        missingArticles.push(cartArticle);
        setArticleNotBuyable(true);
      }
    }
  }, [shopArticles, missingArticles, cartItems]);

  const [articleNotBuyable, setArticleNotBuyable] = useState<boolean>(false);

  const handleClose = () => {
    navigate('/kundvagn');
  };

  //TODO: BEB-207 - Contentful texts are needed for the dialog
  return (
    <Modal
      open={articleNotBuyable}
      aria-labelledby="confirm-title"
      aria-describedby="confirm-description"
    >
      <Box className={styles.ModalBody}>
        <Typography id="confirm-title" variant="h6" component="h2">
          Artiklar inte köpbara
        </Typography>
        <Typography id="confirm-description" sx={{ mt: 2 }} component="p">
          En eller flera artiklar är inte längre möjliga att köpa, det gäller
          följande artiklar:
        </Typography>
        <ul>
          {missingArticles.map((article) => (
            <li key={article.articleNumber}>
              {article.articleTitle} - {article.articleNumber}
            </li>
          ))}
        </ul>
        <Box className={styles.ModalButtonContainer}>
          <Button variant="contained" onClick={handleClose}>
            Till kundvagnen
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
