import { Card, Typography } from '@mui/material';
import { graphql } from 'gatsby';
import React from 'react';
import { ContentfulCustomer } from '../../../contentful';

interface InvoiceAddressProps {
  title: string;
  customer?: ContentfulCustomer;
}

export const InvoiceAddress = ({ title, customer }: InvoiceAddressProps) => {
  return (
    <Card sx={{ boxShadow: 5, maxWidth: 510, p: 2.5, mb: 6 }}>
      <Typography variant="h3" component="h2" sx={{ mb: 2 }}>
        {title}
      </Typography>
      {customer?.customerName}
      <br />
      {customer?.invoiceAddressStreet}, {customer?.invoiceAddressZip},{' '}
      {customer?.invoiceAddressCity}
    </Card>
  );
};

export const query = graphql`
  fragment CustomerInformation on ContentfulCustomer {
    customerNumber
    customerName
    invoiceAddressCity
    invoiceAddressStreet
    invoiceAddressZip
  }
`;
