import { Card, SelectChangeEvent, Typography } from '@mui/material';
import React, { ChangeEvent } from 'react';
import {
  CheckoutDeliveryAddress,
  CheckoutDeliveryRecipient,
  CheckoutUnloading,
  CheckoutDeliveryTime,
} from '../../../contentful';
import { FormState } from '../../../hooks';
import { DeliveryAddress } from '../delivery-address';
import { DeliveryRecipient } from '../delivery-recipient';
import { DeliveryTime } from '../delivery-time';
import { Unloading } from '../unloading';

interface DeliveryProps {
  title: string;
  deliveryTime: CheckoutDeliveryTime;
  unloading: CheckoutUnloading;
  deliveryAdress: CheckoutDeliveryAddress;
  deliveryRecipient: CheckoutDeliveryRecipient;
  onChange: (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
  ) => void;
  values: FormState;
}

export const Delivery = ({
  title,
  deliveryTime,
  unloading,
  deliveryAdress,
  deliveryRecipient,
  onChange,
  values,
}: DeliveryProps) => {
  return (
    <Card sx={{ boxShadow: 5, p: 2.5 }}>
      <Typography variant="h3" component="h2" sx={{ mb: 3 }}>
        {title}
      </Typography>
      <DeliveryTime
        deliveryTime={deliveryTime}
        onChange={onChange}
        values={values}
      />
      <Unloading unloading={unloading} onChange={onChange} values={values} />
      <DeliveryAddress
        deliveryAdress={deliveryAdress}
        onChange={onChange}
        values={values}
      />
      <DeliveryRecipient
        deliveryRecipient={deliveryRecipient}
        onChange={onChange}
        values={values}
      />
    </Card>
  );
};
