import {
  Card,
  Grid,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { graphql } from 'gatsby';
import React, { ChangeEvent } from 'react';
import { CheckoutDeliveryRecipient } from '../../../contentful';
import { FormState } from '../../../hooks';
import * as styles from './delivery-recipient.module.scss';

interface DeliveryRecipientProps {
  deliveryRecipient?: CheckoutDeliveryRecipient;
  onChange: (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
  ) => void;
  values: FormState;
}

export const DeliveryRecipient = ({
  deliveryRecipient,
  onChange,
  values,
}: DeliveryRecipientProps) => {
  return (
    <>
      <Card sx={{ boxShadow: 3, p: 2.5, mb: 3 }}>
        <Typography variant="h3" component="h3" sx={{ mb: 2 }}>
          {deliveryRecipient?.title}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              label={deliveryRecipient?.labelContactPerson}
              variant="outlined"
              fullWidth
              required
              name="receiverName"
              onChange={onChange}
              value={values['receiverName']}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              type="number"
              className={styles.mobileNumber}
              label={deliveryRecipient?.labelPhone}
              variant="outlined"
              fullWidth
              required
              inputMode="tel"
              name="receiverMobileNumber"
              onChange={onChange}
              value={values['receiverMobileNumber']}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export const query = graphql`
  fragment DeliveryRecipient on ContentfulPageCheckout {
    deliveryRecipient {
      labelContactPerson
      labelPhone
      title
    }
  }
`;
