import React from 'react';
import Checkout from '../components/checkout/checkout';
import { graphql, PageProps } from 'gatsby';
import { ContentfulSettings } from '../contentful';
import { Helmet } from 'react-helmet';

interface CheckoutPageQueryProps {
  contentfulSettings: ContentfulSettings;
}

type CheckoutPageType = PageProps<CheckoutPageQueryProps>;

const CheckoutPage = ({ data }: CheckoutPageType) => (
  <>
    <Helmet title={data.contentfulSettings.ecomCommon.checkoutPage.title} />
    <Checkout
      ecomCommon={data.contentfulSettings.ecomCommon}
      checkoutPage={data.contentfulSettings.ecomCommon.checkoutPage}
    ></Checkout>
  </>
);

export const query = graphql`
  query {
    contentfulSettings {
      ecomCommon {
        ...EcomCommon
      }
    }
  }
`;

export default CheckoutPage;
