import {
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { graphql } from 'gatsby';
import React, { ChangeEvent, useState } from 'react';
import { CheckoutDeliveryTime } from '../../../contentful/';
import { FormState } from '../../../hooks';

interface DeliveryTimeProps {
  deliveryTime?: CheckoutDeliveryTime;
  onChange: (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
  ) => void;
  values: FormState;
}

export const DeliveryTime = ({
  deliveryTime,
  onChange,
  values,
}: DeliveryTimeProps) => {
  const [displayTimeUnloading, setDisplayTimeUnloading] =
    useState<boolean>(false);

  const onTimeUnloadingRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDisplayTimeUnloading(event.target.value === 'true');
    return onChange(event);
  };

  const minDeliveryDate = new Date().toISOString().split('T')[0];
  const maxDeliveryDate = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1),
  )
    .toISOString()
    .split('T')[0];

  return (
    <Card sx={{ boxShadow: 3, p: 2.5, mb: 3 }}>
      <Typography variant="h3" component="h3" sx={{ mb: 2 }}>
        {deliveryTime?.title}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            name="deliveryDate"
            onChange={onChange}
            label={deliveryTime?.labelDeliveryDate}
            InputLabelProps={{ style: { backgroundColor: 'white' } }} // Prevent safari from showing todays date as the default value.
            type="date"
            inputProps={{
              min: minDeliveryDate,
              max: maxDeliveryDate,
            }}
            variant="outlined"
            fullWidth
            helperText={deliveryTime?.disclaimer}
            sx={{ backgroundColor: '#fff' }}
            value={values['deliveryDate']}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <FormControl>
            <FormLabel id="unloading-group-label">
              {deliveryTime?.labelTimeUnloading}
            </FormLabel>
            <RadioGroup
              defaultValue={false}
              aria-labelledby="unloading-group-label"
              name="deliveryUnloading"
              onChange={onTimeUnloadingRadioChange}
            >
              <FormControlLabel
                value="false"
                control={<Radio size="small" />}
                label={deliveryTime?.timeUnloadingNo}
                sx={{ ml: 0 }}
              />
              <FormControlLabel
                value="true"
                control={<Radio size="small" />}
                label={deliveryTime?.timeUnloadingYes}
                sx={{ ml: 0 }}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={5} lg={3}>
          {displayTimeUnloading && (
            <FormControl fullWidth>
              <InputLabel id="time-label">{deliveryTime?.labelTime}</InputLabel>
              <Select
                label={deliveryTime?.labelTime}
                name="unloadingTime"
                onChange={onChange}
                labelId="time-label"
                id="time"
                value={values['unloadingTime']}
                required
              >
                <MenuItem value="Välj" disabled>
                  Välj
                </MenuItem>
                {deliveryTime?.timeUnloadingOptions.map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export const query = graphql`
  fragment DeliveryTime on ContentfulPageCheckout {
    deliveryTime {
      deliveryDateDefaultValue
      disclaimer
      labelTime
      labelDeliveryDate
      labelTimeUnloading
      timeUnloadingNo
      timeUnloadingOptions
      timeUnloadingYes
      title
    }
  }
`;
