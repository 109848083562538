import { Box, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { FormState } from '../../../hooks';

interface OrderReferenceProps {
  label: string;
  onChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  values: FormState;
}

export const OrderReference = ({
  label,
  onChange,
  values,
}: OrderReferenceProps) => {
  return (
    <>
      <Box sx={{ maxWidth: 550, my: 6 }}>
        <TextField
          required
          name="customerReferenceNumber"
          onChange={onChange}
          label={label}
          variant="outlined"
          fullWidth
          sx={{ backgroundColor: '#fff' }}
          value={values['customerReferenceNumber']}
          InputProps={{
            inputProps: {
              autoFocus: true,
            },
          }}
        />
      </Box>
    </>
  );
};
