import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { graphql } from 'gatsby';
import React, { ChangeEvent } from 'react';
import { CheckoutUnloading } from '../../../contentful';
import { FormState } from '../../../hooks';

interface UnloadingProps {
  unloading?: CheckoutUnloading;
  onChange: (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
  ) => void;
  values: FormState;
}

export const Unloading = ({ unloading, onChange, values }: UnloadingProps) => {
  return (
    <Card sx={{ boxShadow: 3, p: 2.5, mb: 3 }}>
      <Typography variant="h3" component="h3" sx={{ mb: 2 }}>
        {unloading?.title}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel id="unloading-label">
              {unloading?.labelUnloadingOptions}
            </InputLabel>
            <Select
              labelId="unloading-label"
              id="unloading"
              label={unloading?.labelUnloadingOptions}
              name="unloadingAlternative"
              onChange={onChange}
              value={values['unloadingAlternative']}
            >
              <MenuItem value="">{unloading?.selectDefaultOption}</MenuItem>
              {unloading?.unloadingOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <TextField
        multiline
        rows={4}
        sx={{ mt: 2 }}
        label={unloading?.labelUnloadingInstructions}
        fullWidth
        name="unloadingInstructions"
        onChange={onChange}
        value={values['unloadingInstructions']}
      />
    </Card>
  );
};

export const query = graphql`
  fragment DeliveryUnloading on ContentfulPageCheckout {
    unloading {
      unloadingOptions
      title
      selectDefaultOption
      labelUnloadingOptions
      labelUnloadingInstructions
    }
  }
`;
