import {
  Card,
  Grid,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { graphql } from 'gatsby';
import React, { ChangeEvent } from 'react';
import { CheckoutDeliveryAddress } from '../../../contentful';
import { FormState } from '../../../hooks';
import * as styles from './delivery-address.module.scss';
interface DeliveryAddressProps {
  deliveryAdress?: CheckoutDeliveryAddress;
  onChange: (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
  ) => void;
  values: FormState;
}

export const DeliveryAddress = ({
  deliveryAdress,
  onChange,
  values,
}: DeliveryAddressProps) => {
  return (
    <Card sx={{ boxShadow: 3, p: 2.5, mb: 3 }}>
      <Typography variant="h3" component="h3" sx={{ mb: 2 }}>
        {deliveryAdress?.title}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            label={deliveryAdress?.labelStreet}
            variant="outlined"
            fullWidth
            required
            name="deliveryAddressStreet"
            onChange={onChange}
            value={values['deliveryAddressStreet']}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            type="number"
            className={styles.deliveryZip}
            label={deliveryAdress?.labelZipCode}
            variant="outlined"
            fullWidth
            required
            inputMode="numeric"
            name="deliveryAddressZip"
            onChange={onChange}
            value={values['deliveryAddressZip']}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label={deliveryAdress?.labelCity}
            variant="outlined"
            fullWidth
            required
            name="deliveryAddressCity"
            onChange={onChange}
            value={values['deliveryAddressCity']}
          />
        </Grid>
      </Grid>
      <TextField
        multiline
        rows={4}
        label={deliveryAdress?.labelDriverInstructions}
        fullWidth
        sx={{ mt: 2 }}
        name="instructionToDriver"
        onChange={onChange}
        value={values['instructionToDriver']}
      />
    </Card>
  );
};

export const query = graphql`
  fragment DeliveryAddress on ContentfulPageCheckout {
    deliveryAddress {
      labelCity
      labelDriverInstructions
      labelStreet
      labelZipCode
      title
    }
  }
`;
