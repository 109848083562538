import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { navigate } from 'gatsby';
import React, { FormEvent, useEffect } from 'react';
import { connect } from 'react-redux';
import { ApiCreateOrder, ApiOrder } from '../../api/interfaces';
import { ContentfulCheckoutPage, ContentfulEcomCommon } from '../../contentful';
import {
  FormState,
  useAppDispatch,
  useForm,
  useGetCustomers,
} from '../../hooks';
import { RootState } from '../../store';
import { ShopState, UserState } from '../../store/interfaces';
import { createApiOrder, OrderApiStatus } from '../../store/slices';
import { CheckIfArticlesAreBuyable } from './check-if-article-is-buyable';
import * as styles from './checkout.module.scss';
import { Delivery } from './delivery';
import { InvoiceAddress } from './invoice-address';
import { OrderReference } from './order-reference';

const mapStateToProps = (state: RootState) => {
  return {
    orderStatusFromStore: state.checkout.orderStatus,
    shopStateFromStore: state.shop,
    userStateFromStore: state.user,
  };
};

interface CheckoutProps {
  ecomCommon: ContentfulEcomCommon;
  checkoutPage: ContentfulCheckoutPage;
  orderStatusFromStore: OrderApiStatus;
  shopStateFromStore: ShopState;
  userStateFromStore: UserState;
}

const Checkout = ({
  ecomCommon,
  checkoutPage,
  orderStatusFromStore,
  shopStateFromStore,
  userStateFromStore,
}: CheckoutProps) => {
  const showSpinner = orderStatusFromStore === 'PROCESSING';
  const orderIsComplete = orderStatusFromStore === 'DONE';

  const customers = useGetCustomers();
  const currentCustomer = customers.find(
    (customer) => customer.customerNumber === userStateFromStore.customerNumber,
  );

  const dispatch = useAppDispatch();

  const formState: FormState = {
    customerReferenceNumber: '',
    deliveryDate: '',
    unloadingTime: '',
    unloadingAlternative: '',
    unloadingInstructions: '',
    deliveryAddressStreet: '',
    deliveryAddressZip: '',
    deliveryAddressCity: '',
    instructionToDriver: '',
    receiverName: '',
    receiverMobileNumber: '',
  };

  const { onChange, values } = useForm(formState);

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    const order: ApiOrder = {
      orderPlacer: userStateFromStore.name,
      customerNumber: '' + userStateFromStore.customerNumber,
      customerName: currentCustomer?.customerName,
      customerAddressStreet: currentCustomer?.invoiceAddressStreet,
      customerAddressZip: currentCustomer?.invoiceAddressZip,
      customerAddressCity: currentCustomer?.invoiceAddressCity,
      orderLines: shopStateFromStore.cart,
      messageToCustomerService: shopStateFromStore.messageToCustomerService,
    };

    Object.keys(values).forEach((key) =>
      Object.assign(order, { [key]: values[key] }),
    );

    const createOrder: ApiCreateOrder = {
      order,
    };

    dispatch(createApiOrder(createOrder));
  };

  useEffect(() => {
    if (orderIsComplete) {
      navigate('/order-bekraftelse');
    }
  }, [orderIsComplete]);

  return (
    <>
      <CheckIfArticlesAreBuyable cartItems={shopStateFromStore.cart} />
      <form onSubmit={onSubmit} data-testid="checkout-form">
        <Typography variant="h1" component="h1" sx={{ mt: 4 }}>
          {checkoutPage.title}
        </Typography>
        <OrderReference
          label={ecomCommon.labelOrderReference}
          onChange={onChange}
          values={values}
        />
        <InvoiceAddress
          title={ecomCommon.labelInvoiceAddress}
          customer={currentCustomer}
        />
        <Delivery
          values={values}
          onChange={onChange}
          deliveryAdress={checkoutPage.deliveryAddress}
          deliveryRecipient={checkoutPage.deliveryRecipient}
          deliveryTime={checkoutPage.deliveryTime}
          title={checkoutPage.titleDelivery}
          unloading={checkoutPage.unloading}
        />
        <div className={styles.ctaWrapper}>
          <div>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => navigate('/')}
            >
              {ecomCommon.continueShopping}
            </Button>
          </div>
          <Box sx={{ m: 0, position: 'relative' }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={showSpinner}
            >
              {checkoutPage.buttonSendOrder}
            </Button>
            {showSpinner && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
        </div>
      </form>
    </>
  );
};

export default connect(mapStateToProps)(Checkout);
